<template>
  <section class="main-banner__wrapper">
    <div class="main-banner">
      <h2 class="main-banner__subtitle">
        изучай, развивайся, достигай!
      </h2>
      <h1 class="main-banner__title">
        даем детям доступ к&nbsp;качественному образованию:
        <span>
          домашняя школа, подготовка к егэ и огэ, репетиторы,
          знакомим с миром it и цифровыми профессиями
        </span>
      </h1>
      <button
        class="main-banner__btn m-btn"
        @click="scrollTo('.main-programs')"
      >
        подобрать обучение
      </button>
    </div>

    <su-licenses-modal
      v-if="showLicensesModal"
      @close="showLicensesModal = false"
    />

    <div class="main-banner__blocks">
      <div class="main-banner__block">
        <b><span>^</span>&nbsp;№&nbsp;3</b>
        <p>*крупнейшие EdTech’и по&nbsp;версии Smart Ranking</p>
      </div>
      <div class="main-banner__block">
        <b><span>с</span> 1999 <span>года</span></b>
        <p>обучаем подростков и&nbsp;детей&nbsp;онлайн</p>
      </div>
      <div class="main-banner__block">
        <b><span>></span>&nbsp;500&nbsp;000</b>
        <p>выпускников</p>
      </div>
      <div class="main-banner__block">
        <b>гос. лицензия и&nbsp;аккредитация</b>
        <button
          class="m-btn"
          @click="showLicensesModal = true"
        >
          проверить лицензию
        </button>
      </div>
    </div>
  </section>
</template>

<script>
import scrollTo from '~/utils/scrollTo';
import SuLicensesModal from '../school-university/SuLicensesModal.vue';

export default {
  name: 'MainBanner',
  components: { SuLicensesModal },
  data: () => ({
    showLicensesModal: false,
    scrollTo,
  }),
};
</script>

<style lang="scss" scoped>

.main-banner {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: calc(var(--scale) * 720);
  padding: calc(var(--scale) * 24) calc(var(--scale) * 40) calc(var(--scale) * 40);
  border-radius: calc(var(--scale) * 60);

  background-image: url("/v2/main/banner/bg.webp");
  background-size: cover;
  background-position: right bottom;

  @include media-down($size-tablet) {
    height: calc(var(--scale) * 512);
    padding: calc(var(--scale) * 20) calc(var(--scale) * 8) calc(var(--scale) * 8);
    border-radius: calc(var(--scale) * 32);
    background-image: url("/v2/main/banner/bg-tablet.webp");
  }

  @include media-down($size-mobile) {
    height: calc(var(--scale) * 720);
    background-image: url("/v2/main/banner/bg-mob.webp");
  }

  &__subtitle  {
    max-width: calc(var(--scale) * 1025);
    margin-bottom: calc(var(--scale) * 32);

    font-family: 'Onest', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: calc(var(--scale) * 140);
    line-height: 80%;
    letter-spacing: -0.06em;
    color: #FFFFFF;

    @include media-down($size-tablet) {
      max-width: calc(var(--scale) * 334);
      margin-bottom: calc(var(--scale) * 12);
      padding-inline: calc(var(--scale) * 8);
      font-size: calc(var(--scale) * 62);
      letter-spacing: -0.08em;
      line-height: 75%;
    }
  }

  &__title {
    max-width: calc(var(--scale) * 869);

    font-family: 'Onest', sans-serif;
    font-weight: 500;
    font-size: calc(var(--scale) * 32);
    line-height: 110%;
    color: #FFFFFF;

    @include media-down($size-tablet) {
      max-width: calc(var(--scale) * 334);
      padding-inline: calc(var(--scale) * 8);
      font-size: calc(var(--scale) * 18);
      letter-spacing: -0.02em;
    }

    span {
      opacity: 0.5;
    }
  }

  &__btn {
    margin-top: auto;
    padding: calc(var(--scale) * 44) calc(var(--scale) * 56);
    background-color: #5237E6;
    border-radius: calc(var(--scale) * 40);
    transition: 200ms;

    font-family: 'Onest', sans-serif;
    font-weight: 500;
    font-size: calc(var(--scale) * 32);
    line-height: 110%;
    color: #FFFFFF;

    @include media-down($size-tablet) {
      padding: calc(var(--scale) * 30);
      width: 100%;
      border-radius: calc(var(--scale) * 24);
      font-size: calc(var(--scale) * 18);
    }

    @media (hover: hover) {
      &:hover {
        background-color: #846EFB;
      }
    }
  }
}

.main-banner__blocks {
  margin-top: calc(var(--scale) * 20);
  display: flex;
  gap: calc(var(--scale) * 20);

  @include media-down($size-tablet) {
    margin-top: calc(var(--scale) * 16);
    gap: calc(var(--scale) * 8);
  }

  @include media-down($size-mobile) {
    flex-wrap: wrap;
  }

  .main-banner__block {
    width: calc(25% - (var(--scale) * 60) / 4);
    height: calc(var(--scale) * 192);
    padding: calc(var(--scale) * 24) calc(var(--scale) * 30);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    flex-shrink: 0;

    background-color: #FFFFFF;
    background-position: right bottom;
    background-repeat: no-repeat;
    border-radius: calc(var(--scale) * 40);

    @include media-down($size-tablet) {
      width: calc(25% - (var(--scale) * 24) / 4);
      height: calc(var(--scale) * 128);
      padding: calc(var(--scale) * 12) calc(var(--scale) * 12) calc(var(--scale) * 12) calc(var(--scale) * 16);
      border-radius: calc(var(--scale) * 24);
    }

    @include media-down($size-mobile) {
      width: calc(50% - (var(--scale) * 4));
    }

    b {
      font-family: 'Onest', sans-serif;
      font-weight: 500;
      font-size: calc(var(--scale) * 48);
      line-height: 110%;
      letter-spacing: -0.02em;
      color: #02120F;

      @include media-down($size-tablet) {
        font-size: calc(var(--scale) * 28);
      }
    }

    p {
      font-family: 'Onest', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: calc(var(--scale) * 20);
      line-height: 130%;
      color: #A7ABAC;

      @include media-down($size-tablet) {
        font-size: calc(var(--scale) * 14);
        font-weight: 500;
      }
    }

    button {
      position: relative;
      font-size: calc(var(--scale) * 20);
      line-height: 130%;
      color: #5237E6;

      &:after {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 1px;
        background-color: #5237E6;;
        transition: width 0.2s linear;
      }

      &:hover:after {
        width: 0;
        right: 0;
        left: auto;
      }

      @include media-down($size-tablet) {
        font-size: calc(var(--scale) * 14);
        font-weight: 500;
      }

      &:hover {
        text-decoration: none;
      }
    }

    &:nth-of-type(1) {
      background-image: url("/v2/main/banner/top.svg");
      background-size: auto 100%;

      @include media-down($size-tablet) {
        background-image: url("/v2/main/banner/top-tablet.svg");
        background-size: auto 90%;
      }

      b span {
        color: #22AE73;
      }
    }

    &:nth-of-type(2),
    &:nth-of-type(3) {
      b span {
        color: #A7ABAC;
      }
    }

    &:nth-of-type(4) {
      background-image: url("/v2/main/banner/gerb.svg");
      background-size: auto 100%;

      @include media-down($size-tablet) {
        background-image: url("/v2/main/banner/gerb-tablet.svg");
      }

      b {
        font-weight: 500;
        font-size: calc(var(--scale) * 32);
        line-height: 110%;
        color: #FF5319;

        @include media-down($size-tablet) {
          font-size: calc(var(--scale) * 18);
        }
      }
    }
  }
}
</style>
