<template>
  <section class="main-platform">
    <div class="main-platform__info">
      <h2>своя платформа для&nbsp;ребенка и&nbsp;родителя</h2>
      <div class="main-platform__cheap">
        <button
          v-for="(tab, idx) in tabs"
          :key="idx"
          :class="['m-btn', { active: currentTabIndex === idx }]"
          @click="currentTabIndex = idx"
          v-html="tab.name"
        />
      </div>
      <button
        class="m-btn main-platform__button"
        @click="openModal(MODAL_TYPES.demo)"
      >
        попробовать бесплатно
      </button>
    </div>
    <div class="main-platform__content main-platform__content--desktop">
      <video
        v-if="tabs[currentTabIndex].video"
        :src="`/v2/main/platform/${tabs[currentTabIndex].video}`"
        :poster="`/v2/main/platform/${tabs[currentTabIndex].image}.webp`"
        playsinline
        preload="none"
        @click="togglePlay"
      />
      <img
        v-else
        :src="`/v2/main/platform/${tabs[currentTabIndex].image}.webp`"
        alt="image"
      >
    </div>
    <div class="main-platform__content main-platform__content--mob">
      <video
        v-if="tabs[currentTabIndex].video"
        :src="`/v2/main/platform/${tabs[currentTabIndex].video}`"
        :poster="`/v2/main/platform/${tabs[currentTabIndex].image}-mob.webp`"
        playsinline
        preload="none"
        @click="togglePlay"
      />
      <img
        v-else
        :src="`/v2/main/platform/${tabs[currentTabIndex].image}-mob.webp`"
        alt="image"
      >
    </div>
  </section>
</template>

<script>
import { mapActions } from 'vuex';
import { MODAL_TYPES } from '../common/AppHeader/data';

export default {
  name: 'MainPlatform',
  data: () => ({
    currentTabIndex: 0,
    tabs: [
      { name: 'прогресс и аналитика', image: 'platform', video: null },
      { name: '>10&nbsp;000 материалов', image: 'materials', video: null },
      { name: 'видеоуроки', image: 'video', video: 'video.mp4' },
      { name: 'мини-лекции', image: 'mini', video: 'mini.MOV' },
      { name: 'расписание', image: 'schedule', video: null },
    ],
    MODAL_TYPES,
  }),
  methods: {
    ...mapActions('appHeaderModals', {
      openModal: 'openModal',
    }),

    togglePlay(event) {
      if (event.target.paused) {
        event.target.play();
      } else {
        event.target.pause();
      }
    },
  },
};
</script>

<style scoped lang="scss">

.main-platform {
  position: relative;
  height: calc(var(--scale) * 720);
  background-color: #FFFFFF;
  border-radius: calc(var(--scale) * 60);
  padding: calc(var(--scale) * 24) calc(var(--scale) * 40) calc(var(--scale) * 40);
  display: flex;
  gap: calc(var(--scale) * 20);
  align-items: flex-end;

  @include media-down($size-tablet) {
    border-radius: calc(var(--scale) * 24);
    padding: calc(var(--scale) * 16) calc(var(--scale) * 16) calc(var(--scale) * 8);
    gap: calc(var(--scale) * 8);
    height: calc(var(--scale) * 360);
    align-items: flex-start;
  }

  @include media-down($size-mobile) {
    height: calc(var(--scale) * 620);
    flex-direction: column;
    gap: calc(var(--scale) * 20);
  }

  &__info {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: calc(var(--scale) * 20);

    @include media-down($size-tablet) {
      gap: calc(var(--scale) * 24);
    }

    @include media-down($size-mobile) {
      height: auto;
    }
  }

  h2 {
    font-weight: 500;
    font-size: calc(var(--scale) * 86);
    line-height: 110%;
    letter-spacing: -0.04em;
    color: #02120F;

    @include media-down($size-tablet) {
      font-size: calc(var(--scale) * 40);
    }
  }

  &__cheap {
    display: flex;
    flex-wrap: wrap;
    gap: calc(var(--scale) * 4);

    @include media-down($size-tablet) {
      gap: calc(var(--scale) * 2);
    }

    button {
      padding: calc(var(--scale) * 19) calc(var(--scale) * 32) calc(var(--scale) * 22);

      background-color: #FFFFFF;
      border: 1px solid #A7ABAC;
      backdrop-filter: blur(5px);
      border-radius: calc(var(--scale) * 100);
      transition: 200ms;

      font-weight: 500;
      font-size: calc(var(--scale) * 26);
      line-height: 120%;
      color: #02120F;

      @include media-down($size-tablet) {
        padding: calc(var(--scale) * 6) calc(var(--scale) * 11);
        font-size: calc(var(--scale) * 14);
      }

      @media (hover: hover) {
        &:hover {
          color: #5237E6;
        }
      }

      &.active {
        background-color: #E6CEFF;
        border-color: #E6CEFF;
        color: #5237E6;
      }
    }
  }

  .main-platform__button {
    margin-top: auto;
    width: 100%;
    padding: calc(var(--scale) * 44) calc(var(--scale) * 56);
    background-color: #FF5319;
    border-radius: calc(var(--scale) * 40);
    transition: 200ms;

    font-weight: 500;
    font-size: calc(var(--scale) * 32);
    line-height: 110%;
    color: #FFFFFF;

    @include media-down($size-tablet) {
      position: absolute;
      width: calc(var(--scale) * 720);
      left: calc(var(--scale) * 8);
      bottom: calc(var(--scale) * 8);
      padding: calc(var(--scale) * 30);
      border-radius: calc(var(--scale) * 24);
      font-size: calc(var(--scale) * 18);
    }

    @include media-down($size-mobile) {
      width: calc(var(--scale) * 350);
    }

    &:hover {
      background-color: #FF9C12;
    }
  }

  .main-platform__content {
    flex-shrink: 0;
    width: calc(var(--scale) * 1025);

    @include media-down($size-tablet) {
      width: calc(var(--scale) * 348);
    }

    @include media-down($size-mobile) {
      width: calc(var(--scale) * 350);
      margin-left: calc(-1 * var(--scale) * 8);
    }

    &--desktop {
      @include media-down($size-tablet) {
        display: none;
      }
    }

    &--mob {
      display: none;

      @include media-down($size-tablet) {
        display: block;
      }
    }

    video,
    img {
      position: relative;
      width: 100%;
    }
  }
}

</style>
